import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { getNameSpaces } from "@namespaces";
import { useRouter } from "next/router";
import { useEffect } from "react";
import LoadingSpinner from "../components/Loading";

const Index = () => {
  const { push } = useRouter();

  useEffect(() => {
    push("login");
  }, []);

  return <LoadingSpinner />;
};

export default Index;

export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, getNameSpaces)),
      // Will be passed to the page component as props
    },
  };
}
